/**
 * Basic styling
 */

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  height: 100%;
  min-height: 100%;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: $bg-color;
  font-size: $font-size-base;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .01em;
}

p {
  margin-bottom: .8em;
}

body,
input,
select,
textarea {
  color: $text-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}

a {
  color: $link-color;
  text-decoration: underline;

  &:hover {
    transition: color .2s linear;
    text-decoration: none;
    color: $link-hover-color;
  }
}

strong {
  font-weight: $font-weight-bold;
}

em {
  font-style: italic;
}

p {
  margin: 0 0 1em 0;
}

img {
  display: block;
  margin: 0 0 .6em;
  vertical-align: middle;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-bold;
  line-height: 1em;
  margin: 0 0 1em 0;

  a {
    color: inherit;
    text-decoration: none;
  }

  small {
    font-size: 60%;
  }
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: .9rem;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

hr {
  border: 0;
  border-bottom: solid 1px rgba(144, 144, 144, 0.25);
  margin: 2em 0;

  &.major {
    margin: 3em 0;
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: $gray-color;
  border-left: 4px solid $gray-color-light;
  padding-left: calc($spacing-unit / 2);
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

/**
 * Code formatting
 */
pre,
code {
  font-size: 14px;
  border: 1px solid $gray-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
  font-family: $font-family-code;
}

pre {
  padding: 8px 12px;
  overflow-x: scroll;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}