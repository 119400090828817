//
// container
.container {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
  }
}

.card {
  padding: $spacing-unit;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(#000, .2);
}

.readmore {
  @include transition(color .2s linear);

  &:hover,
  &:focus {
    color: lighten($primary-color, 10%);
    text-decoration: none;
  }
}


//
// Site header
.header {
  background: #292929 url(../assets/cover.jpg) no-repeat 50% 0px;
  @include background-size(cover);
  border-bottom: 1px solid $gray-color-light;
  border-top: 3px solid $primary-color;
  min-height: 60px;
  position: relative;
  @extend %clearfix;
  padding: 60px 0;
  margin-bottom: 30px;

  .overlay {
    background: rgba(#000, .50);
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  a {
    color: $white-color;
  }

  @include media-query($on-phone) {
    text-align: center;
  }

  .txt-center {
    color: $white-color;
    margin-top: 160px;
    font-weight: 300;
  }
}

.home .header {
  padding: 260px 0 200px;
}

//
// site title
.title {
  font-size: 32px;
  line-height: 65px;
  letter-spacing: -1px;
  margin: 0;
  float: left;
  color: darken($white-color, 10%);
  font-family: $font-family-base;

  @include media-query($on-phone) {
    float: none;
    display: block;
    width: 100%;
  }

  & a:hover,
  & a:focus {
    color: lighten($white-color, 10%);
    text-decoration: none;
    border-color: lighten($white-color, 10%);
  }
}

//
// navbar
.navbar {
  float: right;

  @include media-query($on-phone) {
    float: none;
  }
}

//
// ul nav
.nav {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 20px;
    margin: 0;
    text-transform: lowercase;
    float: left;
    display: inline-block;
    @include transition(background .2s linear);

    &:last-child {
      padding-right: 0;
    }

    @include media-query($on-phone) {
      float: left;
      width: (1/3);
      text-align: center;
    }
  }

  a {
    padding: 0;
    display: inline-block;
    text-decoration: none;
    color: darken($white-color, 25%);
    @include transition(color .2s linear);
  }

  li.active a,
  li:hover a,
  li:focus a {
    color: lighten($primary-color, 10%);
    text-decoration: none;
  }
}

//
// menu icon
.menu-icon {
  display: none;
}

//
// post
.post {
  .post-header {
    h1 {
      margin-bottom: 10px;
      // text-transform: uppercase;
      line-height: 1.3em;
      font-size: 1.8rem;
    }

    a {
      color: $text-color;
      @include transition(color .2s linear);

      &:hover,
      &:focus {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }

  .post-meta {
    font-size: 14px;
    margin-top: 0;
    font-weight: 300;
    font-family: $font-family-serif;
    margin-bottom: 1rem;
    font-style: italic;
    color: #9a9a9a;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }
}

.single {
  .post-header {
    margin-bottom: $spacing-unit;
  }
}

.share {
  @extend %clearfix;
  text-align: right;

  span, a {
    font-size: 1em;
    color: #999;
    text-decoration: none
  }
}

.post-list {
  .post {
    padding-bottom: $spacing-unit;
    margin-bottom: $spacing-unit;
    border-bottom: 1px solid $gray-color-light;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .post-content {
      margin-top: .8em;
    }
  }
}

// Social
//
.social-icons {
  @extend %clearfix;
  margin-bottom: 15px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    text-align: center;
    margin-right: 0;

    a {
      font-size: 30px;
      margin: 0;
      color: #9D9D9D;
      font-weight: 400;
      text-shadow: none;
      -webkit-transition: all .5s ease-in;
      -moz-transition: all .5s ease-in;
      -o-transition: all .5s ease-in;
      transition: all .5s ease-in;
      padding-right: 0;
    }
  }

  i {
    font-size: 20px;
    width: 35px;
    height: 35px;
    padding: 7px;
    background: #DADADA;
    text-align: center;
    color: #494949;
    transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
  }

  a:hover i.fa-linkedin {
    background-color: #0077b5;
    color: #fff;
  }

  a:hover i.fa-twitter {
    background-color: #00ABEC;
    color: #fff;
  }

  a:hover i.fa-rss {
    background-color: #f26522;
    color: #fff;
  }
}

// Pagination
//
.pagination {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0 0 20px;
  padding-left: 0;
  position: relative;

  ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .page-number {
    color: #999;
    font-size: .8em;
  }
  .page-number, a {
    height: 38px;
    display: inline-block;
    text-align: center;
  }

  li {
    display: inline-block;
    padding: 0 5px;

    a {
      width: 38px;
      position: relative;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 2px;
      margin: 0;
      color: #636363;
      font-size: 1.4em;
      text-decoration: none;
      cursor: pointer;
      background: #fff;

      &:hover {
        background: $primary-color;
        border-radius: 2px;
        color: #fff;
      }
    }

  }
}

//
// footer
.footer {
  border-top: 1px solid $gray-color-light;
  background-color: $background-color;
  margin-top: 30px;
  padding: 30px 0;

  small {
    color: darken($gray-color-light, 25%);
    font-size: 12px;
  }
}
