
/**
 * Clearfix
 */
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
%vertical-rhythm {
  margin-bottom: calc($spacing-unit / 2);
}
